import { useEffect, useState } from "react";
import { Web3AuthCore } from "@web3auth/core";
import {
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  SafeEventEmitterProvider,
} from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import RPC from "./web3RPC";

const clientId =
  "BG1tzywre8Jr9nVyCgwNP4bwCspYDvO9zY7a0UdNBx3IT77_V0tfxjgE_E6RqvLvYniKHOWmgQyuEHejKZFZPeM"; // get from https://dashboard.web3auth.io

function App() {
    const [web3auth, setWeb3auth] = useState<Web3AuthCore | null>(null);
    const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(
      null
    );
  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3AuthCore({
          clientId,
          chainConfig: {
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: "0x5",
          },
        });

        const openloginAdapter = new OpenloginAdapter({
          adapterSettings: {
            network: "testnet",
            uxMode: "popup",
            loginConfig: {
              discord: {
                name: "Custom Discord Auth Login",
                verifier: "web3auth-core-discord",
                typeOfLogin: "discord",
                clientId: "993506120276648017", //use your app client id you got from discord
              },
            },
          },
        });
        web3auth.configureAdapter(openloginAdapter);
        setWeb3auth(web3auth);

        await web3auth.init();
        if (web3auth.provider) {
          setProvider(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  function uiConsole(...args: any[]): void {
    console.log(...args);
  }

  const logout = async () => {
    if (!web3auth) {
        uiConsole("web3auth not initialized");
        return;
    }
    await web3auth.logout();
    uiConsole("logged out");
    setProvider(null);
    };


  const login = async () => {
    if (!web3auth) {
      uiConsole("web3auth not initialized yet");
      return;
    }
    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        loginProvider: "discord",
      }
    );
    setProvider(web3authProvider);
    uiConsole("Logged in Successfully!");
  };

  const unloggedInView = (
    <button onClick={login} className="card">
      Login
    </button>
  );
  
  const loggedInView = (
    <>
        <div>
            <button onClick={logout} className="card">
                Logout
            </button>
        </div>
    </>
    );

  return (
    <div className="container">
      <h1 className="title">
        Wallet connectors for DDAO.
      </h1>

      <div className="grid">{provider ? loggedInView : unloggedInView}</div>

      <footer className="footer">
        <p>
            build by kenforever.
        </p>
      </footer>
    </div>
  );
}

export default App;